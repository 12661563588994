<template>
    <div style="overflow-y:auto; height: 50vh">
        <v-card
            width="450"
            flat
            class="mx-auto mt-3 px-4"
        >
            <v-form ref="form">
                <v-container
                    v-for="(item, i) in sections"
                    :key="i"
                    fluid
                >                
                    <v-row class="font-weight-bold">{{ item.label }}</v-row>                    
                    <v-row
                        v-for="field in fields[item.relationship]"
                        :key="field.model"
                    >
                        <v-text-field
                            v-model="familyRecords[item.relationship][field.model]"
                            v-if="field.textField"
                            :label="field.label"
                            @blur="update(item.relationship)"
                        >
                        </v-text-field>

                        <v-text-field
                            v-model="familyRecords[item.relationship][field.model]"
                            v-if="field.number"
                            :label="field.label"
                            :onkeypress="isNumber"
                            :hint="field.hint"
                            :rules="field.rules"
                            persistent-hint
                            :maxlength="field.maxlength"
                            :prefix="field.prefix"
                            @blur="update(item.relationship)"
                            class="mb-4"
                        >
                        </v-text-field>

                        <v-textarea
                            v-model="familyRecords[item.relationship][field.model]"
                            v-if="field.textArea"
                            :label="field.label"
                            @blur="update(item.relationship)"
                            rows="1"
                            auto-grow
                        >
                        </v-textarea>

                        <v-select
                            v-model="familyRecords[item.relationship][field.model]"
                            v-if="field.select"
                            :label="field.label"
                            :items="field.items"
                            @change="update(item.relationship)"
                        >
                        </v-select>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    created: function () {
        this.initialize();
    },
    
    props: {
        records: {
            type: Object,
            default: function () {
                return {}
            }
        },

        validate: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        validate: {
            handler (val) {
                if(val) this.update();
            }
        }
    },

    computed: {
        ...mapGetters({
            editedStudent: 'students/getEditedStudent',
        }),

        isNumber () {
            return 'return (event.keyCode >= 48 && event.keyCode <= 57)'
        }, 

    },

    data: () => ({
        sections: [],        
        familyRecords: [],
        fieldSaveIcons: {},
        fields: [],       
    }),

    methods: {
        ...mapActions({
            postDataFamily: 'students/postDataFamily',
        }),

        ...mapMutations({
            setDataFamily: 'students/setDataFamily',
        }),

        initialize () {
            this.setSections();
            this.setModelFields();
        },

        setSections () {
            this.sections = [
                {
                    label: "Father's Information",
                    relationship: 1,
                },
                {
                    label: "Mother's Information",
                    relationship: 2,
                },
                {
                    label: "Guardian Information",
                    relationship: 3,
                },
                {
                    label: "Emergency Contact",
                    emergency: true,
                    relationship: 4,
                },
            ]

            this.setSectionFields();
        },

        setSectionFields () {
            let fields = [
                {
                    label: "Name",
                    model: "name",
                    textField: true,
                    emergency: true,
                    family: true,
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: "Relation to Child",
                    model: "relation",
                    textField: true,
                    emergency: true,
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: "Marital Status",
                    model: "marital_status",
                    select: true,
                    items: ["Married", "Single", "Divorced", "Widowed", "Other"],
                    family: true,
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: "Home Address",
                    model: "address_home",
                    textArea: true,
                    family: true,
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: "Phone (Mobile)",
                    model: "phone_mobile",
                    number: true,
                    prefix: '1868',
                    hint: "Enter 7 Digits only",
                    rules: [ value => (value || '').length == 7 || '7 Digits Needed'],
                    maxlength: 7,
                    emergency: true,
                    family: true,
                    ref: '',                    
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: "Phone (Work)",
                    model: "phone_work",
                    number: true,
                    prefix: '1868',
                    hint: "Enter 7 Digits only",
                    rules: [ value => (value || '').length == 7 || '7 Digits Needed'],
                    maxlength: 7,
                    emergency: true,
                    family: true,
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: "Phone (Home)",
                    model: "phone_home",                    
                    emergency: true,
                    family: true,
                    number: true,
                    prefix: '1868',
                    hint: "Enter 7 Digits only",
                    rules: [ value => (value || '').length == 7 || '7 Digits Needed'],
                    maxlength: 7,
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: "Email",
                    model: "email",
                    textField: true,
                    family: true,
                    rules: [value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    }],
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: "Occupation",
                    model: "occupation",
                    textField: true,
                    family: true,
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: "Employer",
                    model: "employer",
                    textField: true,
                    family: true,
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: "Work Address",
                    model: "address_work",
                    textArea: true,
                    family: true,
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: "ID Card #",
                    model: "id_card",
                    textField: true,
                    family: true,
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                },

            ]            

            this.sections.forEach(section => {
                let sectionFields = [];
                if(!section.emergency){                    
                    sectionFields = fields.filter(field => {
                        return field.family;
                    })
                    this.$set(this.fields, section.relationship, sectionFields);
                }
                else{                  
                    sectionFields = fields.filter(field => {
                        return field.emergency
                    })
                    this.$set(this.fields, section.relationship, sectionFields);
                }
            })

            const record = {
                relationship : null,
                name: null,
                marital_status: null,
                address_home: null,
                occupation: null,
                employer: null,
                address_work: null,
                phone_home: null,
                phone_work: null,
                phone_mobile: null,
                email: null,
                id_card: null,
                relation: null,
                student_id: this.editedStudent.student_id,
            }

            // console.log(record);

            for(let i = 1; i < 5; i++){
                this.$set(this.familyRecords, i, {...record});
                this.familyRecords[i].relationship = i;
            }

        },

        setModelFields () {
            this.familyRecords.forEach((record, index) => {
                if(record){
                    Object.keys(record).forEach(key => {
                        if(this.records[index])
                        this.$set(record, key, this.records[index][key])
                        else if(key != 'student_id' && key != 'relationship'){
                            this.$set(record, key, null);
                        }
                    })
                }
            })
            this.$nextTick(() => {
                if(this.$refs.form){
                    this.$refs.form.resetValidation();
                }                
            })
        },

        async update (index = null) {
            if(this.familyRecords[index] && !this.familyRecords[index].name) return;

            try {

                if(index !== null){
                    this.setDataFamily(this.familyRecords[index]);
                    const { statusText } = await this.postDataFamily();
                    console.log(statusText);
                    return
                }

                for(const record of this.familyRecords){
                    if(!record || !record.name){
                        continue;
                    }
                    this.setDataFamily(record);
                    console.log('record: ', record);
                    const { statusText } = await this.postDataFamily();
                    console.log(statusText);
                    
                }
                this.$emit('save-status', {type:'success', text: 'Data successfully saved!'});
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
                this.$emit('save-status', {type:'error', text: 'An error occurred.'});
            }
        },

    }
}
</script>

<style scoped>
    ::v-deep .v-text-field__slot textarea{
        font-size: 16px;
    }
</style>