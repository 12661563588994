<template>
    <div style="overflow-y:auto; height: 50vh">
        <v-card
            width="450"
            flat            
            class="mx-auto mt-3 px-4"
        >
           
            <v-card-text
                v-for="(section, i) in fields"
                :key="i"
                class="py-0"
            >
                <v-row 
                    v-if="section.group"
                    class="font-weight-light"
                >
                    {{ section.title }}
                </v-row>
                <v-row
                    v-if="section.group"
                >                    
                    <v-col
                        class="flex-grow-0 pl-0 mr-4"
                        v-for="field in section.items"
                        :key="field.model"
                    >
                        <v-card
                            width="150"
                            height="40"
                            flat                            
                        >
                            <v-checkbox                            
                                v-model="record[field.model]"                       
                                :label="field.label"
                                class="mt-0"
                                style="font-size: 0.7rem"
                                @change="update(true)"                                
                            >
                            </v-checkbox>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row
                    v-if="!section.group"
                >
                    <v-col>
                        <v-text-field
                            :label="section.label"
                            v-model="record[section.model]"
                            v-if="section.textField"
                            class="mt-0"
                            @blur="update(true)"
                        >
                        </v-text-field>
                        <v-textarea
                            :label="section.label"
                            v-model="record[section.model]"
                            v-if="section.textArea"
                            rows="1"
                            auto-grow
                            class="mt-0"
                            @blur="update(true)"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>     
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    props: {
        record: {
            type: Object,
            default: function () {
                return {}
            }
        },

        validate: {
            type: Boolean,
            default: false,
        }
    },

    created: function () {
        this.initialize();
    },

    watch: {
        validate: {
            handler (val) {
                if(val) this.update();
            }
        }
    },

    data: () => ({
        fields: []
    }),

    methods: {
        ...mapActions({
            postDataMedical: 'students/postDataMedical',
        }),

        ...mapMutations({
            setDataMedical: 'students/setDataMedical',
        }),

        initialize () {
            this.setFields();
        },  

        setFields () {
            this.fields = [
                {
                    title: "Immunization Records",
                    group: true,
                    items: [
                        {
                            label: "Chicken Pox",
                            model: "chicken_pox",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Diphtheria",
                            model: "diphtheria",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Hepatitis",
                            model: "hepatitis",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Measles",
                            model: "measles",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Polio",
                            model: "polio",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Rheumatic Fever",
                            model: "rheumatic_fever",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Tetanus",
                            model: "tetanus",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Typhoid",
                            model: "typhoid",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Yellow Fever",
                            model: "yellow_fever",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                    ]
                },
                     
                {
                    title: "Health Conditions",
                    group: true,
                    items: [
                        {
                            label: "Asthma",
                            model: "asthma",
                            checkbox: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,                    
                        },
                        {
                            label: "Diabetes",
                            model: "diabetes",
                            checkbox: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,                    
                        },
                        {
                            label: "Epilepsy",
                            model: "epilepsy",
                            checkbox: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,                    
                        },
                        {
                            label: "Poor Eyesight",
                            model: "poor_eyesight",
                            checkbox: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,                    
                        },
                        {
                            label: "Poor Hearing",
                            model: "poor_hearing",
                            checkbox: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,                    
                        },
                        {
                            label: "TB",
                            model: "tb",
                            checkbox: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,                    
                        },
                    ]
                },

                {
                    label: "Allergies",
                    model: "allergies",
                    textArea: true,
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                },

                {
                    label: "Other Health Conditions",
                    model: "other",
                    textArea: true,
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                }
            ]
        },

        async update (autoSave = false) {
            this.setDataMedical(this.record);
            
            try {
                const { statusText } = await this.postDataMedical();
                console.log(statusText);
                if(autoSave) return;
                this.$emit('save-status', {type:'success', text: 'Data successfully saved!'});
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
                this.$emit('save-status', {type:'error', text: 'An error occurred.'});
            }
        },

       
    }
}
</script>

<style scoped>
    ::v-deep .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
        font-size: 0.8rem;
    }

    ::v-deep .v-text-field__slot textArea{
        font-size: 16px;
    }
</style>