<template>
     <v-row>           
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="1000"                
            >
                <v-card-title>
                    Edit / View Students                   
                    
                    <v-spacer></v-spacer>
                    
                    <students-attendance></students-attendance>

                    <v-dialog
                        :value="dialog"
                        max-width="780px"
                        persistent
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                outlined
                                text
                                @click="dialog=true"
                                v-on="on"
                                v-bind="attrs"
                                class="mr-3"
                            >
                                Add New Student
                            </v-btn>                            
                        </template>

                        <students-edit
                            v-if="dialog"                            
                            v-bind:formTitle="formTitle"
                            v-bind:formClasses="formClasses"
                            v-on:close="closeEdit"
                            v-on:update-students="initialize" 
                        ></students-edit>
                             
                    </v-dialog>

                    <StudentsUpload
                        v-on:update-students="initialize"
                    ></StudentsUpload>                    
                    
                </v-card-title>

                <!-- Student filter current / archive -->
                <div class="pl-2">
                    <v-btn 
                        :color="current.color" 
                        :depressed="current.depressed" 
                        class="ma-2" 
                        x-small
                        tile
                        @click="filterCurrent()"
                    >
                    <v-icon v-if="currentStudents" small left light>mdi-check-circle</v-icon>
                        Current
                    </v-btn>
                        <v-btn 
                        :color="archive.color" 
                        :depressed="archive.depressed" 
                        class="ma-2" 
                        x-small
                        tile
                        @click="filterArchive()"
                    >
                        <v-icon v-if="!currentStudents" left light>mdi-check-circle</v-icon>
                        Archive
                    </v-btn>
                </div>

                <!-- Student data table -->
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="students"
                        fixed-header                        
                        height="45vh"
                        :calculate-widths="true" 
                        :search="search"
                        :loading="loading"                        
                        item-key="student_id"                                            
                    >
                        <template v-slot:top>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search Student"
                                single-line
                                hide-details
                                class="mb-8"
                                clearable
                            ></v-text-field>

                            <!-- Update Status Dialog -->
                            <v-dialog
                                v-model="dialogUpdateStatus"
                                max-width="430px"
                                persistent
                            >
                                <v-expand-transition>
                                    <v-card
                                        v-show="updatingStatus"
                                        color="primary"
                                        dark
                                        class="pa-4"
                                    >
                                        <v-card-text>
                                        {{ studentStatusUpdated }}
                                        <v-progress-linear
                                                indeterminate
                                                color="white"
                                                class="mb-0 mt-4"
                                        ></v-progress-linear>
                                        </v-card-text>
                                    </v-card>
                                </v-expand-transition>
                            </v-dialog>
                        </template>

                        <template v-slot:item="props">
                            <tr>
                                <td>
                                    <v-img
                                        :src="imageUrl(props.item.picture)"
                                        max-width="50"
                                        class="my-4"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </td>
                                <td>{{ props.item.student_id }}</td>
                                <td>{{ props.item.name }}</td>
                                <td>{{ props.item.form_class_id }}</td>
                                <td>{{ props.item.gender }}</td>
                                <td>{{ props.item.date_of_birth }}</td>
                                <td>
                                    <v-menu
                                        v-model="props.item.menu"
                                        :close-on-content-click="false"
                                        :close-on-click="false"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                                tile
                                                x-small
                                            >
                                                {{ props.item.student_status }}
                                            </v-btn>
                                        </template>

                                        <v-card>
                                            <v-card-title>Change Student Status</v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <v-radio-group class="mt-0" v-model="props.item.student_status_id">
                                                    <v-radio
                                                        v-for="status in statuses"
                                                        :key="status.id"
                                                        :label="status.detail"
                                                        :value="status.id"
                                                        class="mt-3"
                                                    ></v-radio>
                                                </v-radio-group>
                                            </v-card-text>

                                            <v-card-actions class="flex-column align-stretch">
                                                <v-row>
                                                    <v-col>
                                                        <v-btn 
                                                            block 
                                                            small 
                                                            color="primary"
                                                            @click="updateStudentStatus(props.item)"
                                                        >
                                                            Change Status
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-btn 
                                                            block 
                                                            small 
                                                            depressed 
                                                            @click="props.item.menu = false"
                                                        >
                                                            <v-icon left>mdi-close</v-icon>
                                                            Close
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-actions>
                                            
                                        </v-card>
                                    </v-menu>
                                </td>
                                <td>
                                    <v-btn
                                        tile
                                        color="primary"
                                        @click="editStudent(props.item)"
                                        x-small
                                        block
                                    >
                                        Edit/View
                                       
                                        
                                    </v-btn>  
                                </td>
                            </tr>
                        </template>                       

                    </v-data-table>

                    <v-snackbar
                        v-model="snack"
                        :timeout="3000"
                        color="primary"
                    >
                        {{ snackText }}
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                v-bind="attrs"
                                text
                                @click="snack = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                    
                </v-card-text>  
                
                <!-- progress overlay -->
                <v-overlay
                    absolute
                    :value="overlay"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>   
            </v-card>            
        </v-col>
     </v-row>            
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import StudentsEdit from './StudentsEdit';
import StudentsAttendance from './StudentsAttendance.vue';
import StudentsUpload from './StudentsUpload.vue';

export default{ 
    components: {         
        StudentsEdit,
        StudentsAttendance, 
        StudentsUpload,
    },

    created () {
        this.initialize();
    },    

    data: () => ({
        headers: [
            {text: 'Picture', align: 'start', sortable: false, value: 'picture', width: '100'},
            {text: 'ID', align: 'start',  value: 'student_id', width: '100'},
            {text: 'Name', align: 'start', sortable: false, value: 'name', width: '150'},
            {text: 'Class', align: 'start',  value: 'form_class_id', width: '150'},
            {text: 'Gender', align: 'start',  value: 'gender', width: '90'},
            {text: 'DOB', align: 'start', value: 'data_of_birth', width: '120'},
            {text: 'Status', align: 'start', value: 'student_status', width: '100'},
            {text: '', align: 'start', sortable: false, value: 'actions', },
        ],
        students: [],
        search: '',
        loading: true,
        snack: false,
        snackText: '',
        options: {
            itemsPerPage: -1,
        },
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {},
        deletedItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: ''
        },
        defaultItem: {
            student_id: '', 
            first_name: '',
            last_name: '',
            other_name: '',
            gender: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            form_class_id: '',
            form_level: '',
            name: '',
        },   
        statuses: [],
        status: 3,
        deleteStudentSelected: null,
        confirmDelete: true,
        deleting: false,
        formClasses: [], 
        dialogUpdateStatus: false,     
        updatingStatus: false,  
        current: {
            color: 'primary',
            depressed: false,
        },
        archive: {
            color: 'gray',
            depressed: true
        },
        currentStudents: true,  
        overlay: false,        
    }),

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Student' :  
            this.editedItem.first_name + ' '
            + this.editedItem.last_name
        },
        studentStatusUpdated () {
            return `Updating ${this.deletedItem.first_name} ${this.deletedItem.last_name} Status`;
        }
    },

    watch: { 
        dialogDelete (val) {
            val || this.closeDelete()
        },
        
        dialog (val) {
            if(val && this.editedIndex == -1){
                this.setNewStudent()
            } 
        }
       
    },

    methods: {
        ...mapActions({
            getStudents: 'students/getStudentsAll',
            getFormClasses: 'common/getFormClasses',
            getStudentStatus: 'students/getStudentStatus',
            delete: 'students/deleteStudent',            
        }),

        ...mapMutations({
            setEditedStudent: 'students/setEditedStudent',
        }),

        async initialize(){
            this.loading = true;
            this.overlay = true;
            
            try {
                const [ 
                    { data: students }, 
                    { data: formClasses },
                    { data: dataStudentStatuses}
                ] = 
                await Promise.all([
                    this.getStudents(), 
                    this.getFormClasses(),
                    this.getStudentStatus()
                ]);
                
                this.mapStudents(students);
                this.formClasses = formClasses;
                this.mapStudentStatuses(dataStudentStatuses);
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
            }
            this.overlay = false;
            this.loading = false;
        },

        mapStudentStatuses(data){
            this.statuses = [];
            data.forEach(record => {
                if(record.detail !== 'Graduated'){
                    this.statuses.push(record);
                }
            })
        },

        mapStudents (data) {
            const { current_students, archived_students } = data;
            
            this.studentsCurrent = current_students.map(value => {
                    value.name = value.last_name + ', ' + value.first_name;
                    if(!value.picture){
                        value.picture = require("../assets/icons/student2.svg")
                    }
                    return value;
                });

            this.students = this.studentsCurrent;

            this.studentsArchive = archived_students.map(value => {
                value.name = value.last_name + ', ' + value.first_name;
                if(!value.picture){
                    value.picture = require("../assets/icons/student2.svg")
                }
                return value;
            })
        },

        editStudent(item){
            this.setEditedStudent({...item});
            this.editedIndex = this.students.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        
        updateStudentStatus(item) {
            const index = this.students.findIndex(student => student.student_id === item.student_id);
            this.students[index].menu = false;
            this.deletedItem = Object.assign({}, item);
            this.deleteStudentSelected = item.first_name + ' ' + item.last_name;
            if(item.student_status_id === 1 && this.currentStudents) return;
            this.updateStudentStatusConfirm();
        },
        
        closeDelete(){
            this.dialogDelete = false;
            this.status = 3;            
        },

        async updateStudentStatusConfirm(){
            console.log('updatingStatus...');
            this.dialogUpdateStatus = true;
            this.updatingStatus = true;
            this.setEditedStudent({
                student_id: this.deletedItem.student_id,
                student_status_id: this.deletedItem.student_status_id
            });

            try {
                await this.delete();
                const { data } = await this.getStudents();
                this.mapStudents(data);             
                this.updatingStatus = false; 
                if(!this.currentStudents) this.filterArchive();                              
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
            }
            
            setTimeout(() =>{
                this.dialogUpdateStatus = false;                 
            })

            setTimeout(() => {
                this.snack = true;
                this.snackText = this.deletedItem.first_name + ' ' + this.deletedItem.last_name + ' status updated.'
            })           
            
        },

        closeEdit () {
            this.dialog = false;            
            this.$nextTick( () => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.setEditedStudent(this.editedItem);
                this.editedIndex = -1;
            });            
        },

        setNewStudent () {
            console.log('set edited student')
            this.setEditedStudent(this.editedItem);
        },

        imageUrl (picture){
            return `${picture}?timestamp=${new Date().getTime()}`
        },

        filterArchive () {
            this.archive.color = 'primary';
            this.archive.depressed = false;
            this.current.color = 'gray';
            this.current.depressed = true;
            this.students = [];
            this.currentStudents = false;
            this.$nextTick(() => {
                this.students = this.studentsArchive
            })
        },

        filterCurrent () {
            this.archive.color = 'gray';
            this.archive.depressed = true;
            this.current.color = 'primary';
            this.current.depressed = false;
            this.students = [];
            this.currentStudents = true;
            this.$nextTick(() => {
                this.students = this.studentsCurrent
            })
        },
    }
}
</script>

<style scoped>
    ::v-deep .v-dialog{
        max-height: 100%
    }
        
    ::v-deep th span{
        display: inline;
    }
</style>